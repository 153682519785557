<script setup>
import { computed } from '#imports';

const props = defineProps({
  authors: {
    type: Array,
    required: true,
  },
  fontColor: {
    type: String,
    default: '',
  },
  avatarSize: {
    type: String,
    validator: (value) => ['small', 'medium', 'big'].includes(value),
    default: 'small',
  },
  isMainCard: {
    type: Boolean,
    default: false,
  },
  isArticleItem: {
    type: Boolean,
    default: false,
  },
});

const names = computed(() => props.authors.map(({ name }) => name).join(', '));
const classes = computed(() => ({
  'post-card-authors--main-card': props.isMainCard,
  'post-card-authors--article-item': props.isArticleItem,
}));
const fontColorStyles = computed(() => {
  if (!props.fontColor) return '';
  return {
    color: props.fontColor,
    opacity: 0.6,
  };
});
</script>

<template>
  <div class="post-card-authors" :class="classes">
    <div
      class="post-card-authors__names"
      :style="fontColorStyles"
      :title="names"
    >
      {{ names }}
    </div>
    <div class="post-card-authors__avatars">
      <BaseAvatar
        v-for="(author, index) in authors"
        :key="author._key"
        :image="author.image"
        :name="author.name"
        :size="avatarSize"
        class="post-card-authors__avatars-item"
        :style="{ zIndex: authors.length - index }"
      />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.post-card-authors {
  display: inline-grid;
  grid-template-columns: minmax(0%, max-content) min-content;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  max-width: 100%;

  &__names {
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: var(--white-color);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: default;
  }

  &__avatars {
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__avatars-item {
    position: relative;
  }

  &__avatars-item:not(:last-child) {
    margin-right: -10px;
  }

  &--main-card {
    .post-card-authors__names {
      font-size: 18px;
      line-height: 22px;
      font-weight: 400;
    }
  }

  &--article-item {
    grid-template-columns: min-content minmax(0%, max-content);
    gap: 8px;
    grid-auto-flow: dense;
    justify-content: flex-start;

    .post-card-authors__avatars {
      grid-column-start: 1;
    }

    .post-card-authors__names {
      grid-column-start: 2;
      font-size: 12px;
      line-height: 18px;
      color: rgba(var(--text-dark-color--rgb), 40%);
    }
  }

  @media (--small-vp) {
    &--main-card {
      .post-card-authors__names {
        font-size: 12px;
        line-height: 16px;
        opacity: 0.6;
      }
    }

    &--article-item {
      grid-template-columns: minmax(0%, max-content) min-content;
      justify-content: flex-end;
      gap: 8px;

      .post-card-authors__avatars {
        grid-column-start: 2;
      }

      .post-card-authors__names {
        grid-column-start: 1;
        font-size: 12px;
        line-height: 18px;
        color: rgba(var(--text-dark-color--rgb), 40%);
      }
    }
  }
}
</style>
